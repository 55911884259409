.pagesContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  line-height: 1.6;
  color: #333;
}

.guidelinesCell h2 {
  text-align: center;
  color: #1976d2;
  margin: 20px 0;
}

.guidelinesCell h4 {
  margin: 20px 0 10px;
  color: #1976d2;
}

.guidelinesCell p {
  margin-bottom: 20px;
}

.guidelinesCell ul,
.guidelinesCell ol {
  margin-bottom: 20px;
  padding-left: 20px;
}

.guidelinesCell ul li,
.guidelinesCell ol li {
  margin-bottom: 10px;
}

.guidelinesCell b {
  color: #1976d2;
}

.guidelinesCell a {
  color: #1976d2;
  text-decoration: none;
}

.guidelinesCell a:hover {
  text-decoration: underline;
}

.tableContainer {
  margin-top: 15px;
}

.table {
  min-width: 700px;
  border-collapse: collapse;
  width: 100%;
}

.tableHead {
  background-color: #f1f1f1;
}

.tableRow {
  border-bottom: 1px solid #ddd;
}

.tableCell,
.tableCellLeft {
  padding: 12px;
  text-align: left;
}

li {
  margin-bottom: 10px;
}
