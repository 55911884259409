/* AboutUs.module.css */

.aboutContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  line-height: 1.6;
  color: #333;
}

.aboutContainer h1,
.aboutContainer h2 {
  text-align: center;
  color: #1976d2;
  margin: 20px 0;
}

.aboutContainer p {
  margin-bottom: 20px;
}

.aboutContainer p em b {
  background-color: yellow;
}

.aboutContainer b {
  color: #1976d2;
}
