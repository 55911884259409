.contact-container {
  display: flex;
  width: 80%;
  margin: 0 auto;
  gap: 15px;
  background-color: black;
  /* padding: 0 0 10px 0; */
  /* color: gold; */
  color: #08FAEB;
  padding: 10px;
  margin-top: 1.5%;
}

.contact-left,
.contact-right {
  width: 50%;
  /* background-color: black; */
}

.contact-left {
  /* background-color: black; */
  /* color: gold; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contact-right {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}

.contact-right > input,
textarea {
  padding: 10px;
  margin: 5px;
}

@media only screen and (max-width: 820px) {
  .contact-container {
    flex-direction: column;
    padding: 10px;
    width: 80%;
  }
  .contact-left,
  .contact-right {
    width: 100%;
  }
}
