/* ContactUs.module.css */

.contactContentContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  line-height: 1.6;
  color: #333;
}

.contactContentContainer p {
  margin-bottom: 20px;
}

.contactContentContainer em {
  display: block;
  margin-top: 20px;
  color: #1976d2;
}

.contactContentContainer b {
  color: #1976d2;
}
