.top-managers-container{
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-evenly;
    flex-wrap: wrap;
    min-height: 48vh;
}



@media only screen and (max-width: 1020px) {
    .top-managers-container{
        flex-direction: column;
        gap: 30px;
    }

}