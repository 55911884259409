.rate-school-container{
    margin: 0 auto;
    width: 80%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
}

.rate-school-container > h1{
    text-align: center;
}


.rate-cell{
    box-shadow: rgb(126 126 126 / 25%) 0px 4px 4px;;
    border: 1px solid lightgray;
    width: 90%;
    height: 100px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 1.5rem;
}

.inner-rate-cell{
    display: flex;
    justify-content: center;
    padding: 10px;
}

.inner-rate-cell > input{
    padding: 1rem;
    width: 300px;
    border-radius: 10px;
    font-size: large;
}

