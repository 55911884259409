/* Guidelines.module.css */

.pagesContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.guidelinesCell {
  line-height: 1.6;
  color: #333;
}

.guidelinesCell p {
  margin-bottom: 20px;
}

.guidelinesCell b {
  color: #1976d2;
}

.guidelinesCell br {
  display: none; /* Remove the <br> tags */
}
