/* TermsOfUse.module.css */

.pagesContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  line-height: 1.6;
  color: #333;
}

.guidelinesCell {
  color: #333;
}

.guidelinesCell h2 {
  text-align: center;
  color: #1976d2;
  margin: 20px 0;
}

.guidelinesCell h3 {
  color: #1976d2;
  margin-top: 20px;
}

.guidelinesCell h4 {
  color: #1976d2;
  margin-top: 20px;
}

.guidelinesCell p {
  margin-bottom: 20px;
}

.guidelinesCell ol,
.guidelinesCell ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

.guidelinesCell ol li,
.guidelinesCell ul li {
  margin-bottom: 10px;
}

.guidelinesCell b {
  color: #1976d2;
}

.guidelinesCell a {
  color: #1976d2;
  text-decoration: none;
}

.guidelinesCell a:hover {
  text-decoration: underline;
}
