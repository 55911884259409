.styled-container {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-top: 90px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: none;
}

/* This is a typical breakpoint for tablets and desktops. You can adjust it based on your requirements. */
@media (min-width: 768px) {
    .styled-container {
        width: 70%;
        /* background-color: red; */
    }
}
