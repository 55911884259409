/* .nav-bar {
  width: 100%;
  height: 70px;
  background-color: #08FAEB;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
} */

/* .social-icons {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 1%;
}

.social-icons > a {
  text-decoration: none;
  color: inherit;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 2%;
}

.logo > a {
  text-decoration: none;
  color: black;
}

.fa:hover {
  cursor: pointer;
}

.search-area {
  display: flex;
}

.search-btns {
  margin-left: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 820px) {
  .search-area {
    display: none;
  }
  .page-links{
    display: none;
  }
} */

/* New CSS */

.search-area {
  display: flex;
}

.search-btns {
  margin-left: 10px;
  margin-right: 10px;
}


.nav-bar {
  /* width: 100%;
  height: 70px; */
  background-color: #08faeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.page-links > ul {
  list-style: none;
  display: flex;
  gap: 30px;
}

.page-links > ul > li > a {
  text-decoration: none;
  color: black;
}

.page-links > ul > li > a:hover {
  font-weight: bold;
}

.navbar {
  background-color: #08faeb;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #08faeb;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
  z-index: 1;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #08faeb;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 820px) {
  .search-area {
    display: none;
  }
  .page-links {
    display: none;
  }
}
