/* ManagerCard.module.css */

.card {
  min-width: 275px;
  margin-top: 25px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardContent {
  padding: 16px;
}

.cardTitle {
  font-size: 24px;
  margin-bottom: 12px;
  color: #333;
}

.cardSubtitle {
  margin-bottom: 15px;
  color: #777;
}

.cardBody {
  color: #555;
}

.cardActions {
  padding: 16px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}

.viewButton {
  color: #1976d2;
  border: 1px solid #1976d2;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.viewButton:hover {
  background-color: #1976d2;
  color: #fff;
}
