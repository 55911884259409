.rate-professor-container {
  margin: 0 auto;
  width: 80%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}

.rate-professor-container > h1 {
  text-align: center;
}

.rate-cell {
  box-shadow: rgb(126 126 126 / 25%) 0px 4px 4px;
  border: 1px solid lightgray;
  width: 90%;
  min-height: 100px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 1.5rem;
}
.right-description{
margin-top: 7px;
gap: 10px;  
display: flex;
/* flex-direction: column; */
  align-items: center;
}

.guidelines-cell {
text-align: left;
  color: gray;
  box-shadow: rgb(126 126 126 / 25%) 0px 4px 4px;
  border: 1px solid lightgray;
  width: 90%;
  /* min-height: 100px; */
  margin: 0 auto;
  padding: 20px;
  margin-top: 1.5rem;
}

.inner-rate-cell {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.inner-rate-cell > input {
  padding: 1rem;
  width: 300px;
  border-radius: 10px;
  font-size: large;
}
